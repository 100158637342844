<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" md="6" class="text-center">
        <span class="font-weight-black">MEMERIKSA AUTHENTIKASI ANDA</span>
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
          class="mt-2"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Cookie from "@/helper/cookie.js";

export default {
  data: () => ({
    session: Cookie.get("myCookie"),
    token: "",
    result: "",
    parsedParams: {},
  }),

  mounted() {
    this.check();
  },

  methods: {
    check() {
      if (!this.session && !this.$route.hash) {
        this.login();
      }

      if (this.$route.hash !== "") {
        this.myFunction();
      }

      if (this.session != "" && this.$route.hash == "") {
        this.login();
      }
    },

    async myFunction() {
      this.$route.hash
        .split("&")
        .map((part) => part.replace(/#/, ""))
        .forEach((param) => {
          const parts = param.split("=");
          this.parsedParams[parts[0]] = parts[1];
        });

      await Cookie.set("token", this.parsedParams.access_token);
      this.token = await this.parsedParams.access_token;
      this.cekuser();
    },

    cekuser() {
      const url = process.env.VUE_APP_AUTH + "user/info";
      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            Cookie.set(
              "myCookie",
              Cookie.enc(JSON.stringify(res.data.mapData))
            );
            this.$router.push("/").catch(() => {});
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    login() {
      window.location.href = process.env.VUE_APP_LOGIN;
    },
  },
};
</script>
